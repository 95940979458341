body {
  background-image: url("images/background.jpg");
  background-size: cover;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.links {
  position: absolute;
  top: 8%;
  left: 15%;
  display: flex;
  flex-direction: column;
}

.links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 26px;
  font-style: italic;
  cursor: pointer;
  user-select: none;
}

.links a:hover {
  color: lime;
  font-style: normal;
  font-size: 32px;
  margin-left: -10px;
}

.logo {
  margin-left: -5px;
}

.unmute {
  display: none;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  color: lime;
  font-weight: bold;
  text-shadow: black 0 0 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 40px;
  font-style: italic;
  cursor: default;
}

footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

footer a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  font-style: italic;
  cursor: pointer;
  user-select: none;
  padding: 20px 0;
  margin: 0 2px;
}
